import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationFAQ from '../assets/images/illustrations/question_state.png';

const SecondSection = () => (
  <section className="second-section">
    <div className="container">

      <h4>Categories</h4>
      <ol>
        <li><a href="#account">Your account</a></li>
        <li><a href="#activities">Activities</a></li>
        <li><a href="#feed">The feed</a></li>
        <li><a href="#redemptions">Redemptions</a></li>
        <li><a href="#privacy">Privacy & Security</a></li>
      </ol>
      <br/>   
      <h4 id="account">Your account</h4>
      <h4>How do I join Sprive Surveys?</h4>
      <p>Becoming a member is easy and free!</p>
      <p>
        All you have to do is click on the link in your Sprive app, accept the terms
        and conditions & privacy policy, and your account is created!
      </p>

      <h4>Can anyone join Sprive Surveys?</h4>
      <p>Anyone who is 16 years of age or above is welcome to join Sprive Surveys.</p>

      <h4>Can I have more than one Sprive Surveys account?</h4>
      <p>You are only allowed to have one account.</p>
      <p>
        If we discover you’re running more than one Sprive Surveys account, all of
        your accounts may be closed and you may be permanently blocked from our
        service and all rewards forfeited.
      </p>

      <h4>How do I close my account?</h4>
      <p>To close your account please reach out to the customer service team</p>
      <br/>
      <h4 id="activities">Activities</h4>
      <h4>What is a Sprive Surveys activity?</h4>
      <p>
        Sprive Surveys activities are a combination of paid activities and quick
        interactions that help build your profile.
      </p>
      <h4>Profile activities</h4>
      <p>
        These are quick questions that help you complete your profile. While you
        don’t receive any cash rewards for answering these, they add lots of value
        to your profile. The more profile update questions you complete, the more
        relevant earning opportunities we can send your way. Plus, they’re
        super-quick to complete.
      </p>
      <h4>Paid activities</h4>
      <p>
        These are earning opportunities that are personalised to you. The more
        activities you participate in, the more complete your profile becomes. And
        once we know you better we can send even more relevant earning opportunities
        and offers your way.
      </p>
      <h4>How much can I earn for completing an activity?</h4>
      <p>
        The rewards for activities vary depending on the length and type of
        activity. You will also be paid a small fee in the event that you fail to
        meet the criteria and cannot complete a survey. Each activity will tell you
        how much you could get paid before you take part in it.
      </p>
      <p>
        Within your feed, we’ve also introduced simple profile update questions so
        you can easily build your profile, even while you’re on the go. These allow
        you to interact and answer questions by clicking on each feed tile. Although
        you may not get paid for each profile update question, every response will
        help connect you to more paid activities.
      </p>
      <h4>How do I get matched to activities?</h4>
      <p>
        Surveys are matched to relevant people based on their profile information.
        While we can’t promise you a certain number of activities each week, the
        more information you share and connect with your Sprive Surveys account, the
        more opportunities you have to have surveys matched to you.
      </p>
      <h4>How many activities will I get?</h4>
      <p>
        We cannot guarantee the number or frequency of activities you will receive
        in a given time period as these depend on a number of things like how many
        responses are required for each survey, and other factors such as where you
        live, how old you are, and other profile-related information. The more
        profile questions you can answer, the greater your chance of being targeted
        for further activities. The best way to ensure you reach an activity in its
        early stages or you don’t miss out completely is to participate regularly
        and refresh your feed for new activities to pop up.
      </p>
      <p>
        I received a message that I could not participate due to VPNs not being
        permitted?
      </p>
      <p>
        A VPN (virtual private network) is a service that encrypts your internet
        traffic data. The VPN hides your IP address and encrypts all the data you
        send or receive to us which may fail some of our security checks on
        activities where this may be a requirement. If you are using a VPN or think
        the network connection you are on has it enabled please switch it off/ try a
        different network source when participating with Sprive Surveys.
      </p>
      <h4>
        What do I do if I completed an activity but did not get the rewards?
      </h4>
      <p>
        We’re always working to fix technical errors. If you completed a paid
        activity and believe you didn’t get the earnings for it, please contact our
        customer service team. Remember to include as much information about the
        survey as you can, including the name, ID, potential earnings or any details
        about the survey topic and what went wrong.
      </p>
      <h4>How do I check that I have received the earnings for the activities I complete?</h4>
      <p>
        Your cash reward is added to your Sprive account. You’ll be able to track
        your earnings 1 day after completing a survey through your Sprive account
        and can redeem them after 60 days.
      </p>
      <h4>Why am I asked so many screening questions in some activities?</h4>
      <p>
        Some studies are more complex than others and as such ask specific questions
        to ensure you are a good match for the activity before you qualify for the
        main questionnaire. We do try to keep screening questions to a minimum,
        however, in some instances simple demographic questions are not enough to
        ensure our clients have the right audience.
      </p>

      <h4>Why am I being restricted from participating whilst traveling?</h4>
      <p>
        Our clients want to hear from people who are present in the country when
        they are taking their surveys. This is to ensure that responses are coming
        from lived experience for the region and to avoid responses based on regions
        that are not related to the survey subject. Please do note it’s against our
        Terms of Service to register an account under a country you do not reside
        in. If you are registered to your country of residence and are traveling, we
        advise resuming participation with Sprive Surveys, once you are back from
        your leave.
      </p>

      <h4>I am facing issues with an activity, is there anything I can do myself?</h4>
      <p>
        Before reaching out to our customer support team, please attempt the
        following for resolution:
      </p>
      <ul>
        <li>
          Copy the ID of the survey so you can find it in the feed again. You can
          find this at the bottom right of the invite tile.
        </li>
        <li>Clear the app cache/cookies</li>
        <li>Ensure you are using the latest version of the app/browser</li>
      </ul>
      <br/>
      <h4 id="feed">The feed</h4>
      <h4>What’s the feed?</h4>
      <p>
        The feed is the first thing you see when you log in to your Sprive Surveys
        account. It’s where you’ll find all of your activities and get quick access
        to your transaction activity and rewards options.
      </p>

      <h4>What is my profile?</h4>
      <p>
        Your profile is a part of an interactive feed. The more you interact with
        your feed, the more personalised your profile becomes. You can find all your
        available profile activities under the ‘Profile’ tab at the top of your feed.
      </p>

      <h4>How do I use the feed?</h4>
      <p>
        Your feed contains profile update questions and earning opportunities matched
        to you. If you’re new to Sprive Surveys, you’ll see mainly profile update
        questions in your feed. Click on them to give your answers and build your
        profile. The more profile questions you complete the more earning
        opportunities such as paid surveys, offers, and content you’ll receive. If
        you’ve been a Sprive Surveys member for a while, you may have already
        completed most or all your profile questions, so you’ll see more activities,
        offers, and content in your feed. Each piece of content in your feed will
        tell you the type of activity it is, how much you could earn for taking
        part, and how long it will take to complete. Simply click on the tiles to
        participate.
      </p>

      <h4>Why am I being asked to answer some of the same questions again?</h4>
      <p>
        You might notice your feed is showing questions you might have already
        answered in the past. You’re being asked to update your answers to certain
        questions so we can ensure your profile is accurate and reflects recent
        events in your life, such as moving house, changing jobs, or getting
        married. This means the content and activities you receive will be relevant
        and optimized for you.
      </p>
      <br/>
      <h4 id="redemptions">Redemptions</h4>
      <h4>Where do I find my earnings?</h4>
      <p>
        Your earnings are added to your Sprive account balance and displayed in your
        Sprive account.
      </p>

      <h4>How do I utilise my earnings?</h4>
      <p>
        Your earnings are all added to your Sprive balance as cash to put towards
        your mortgage. Please reach out to the Sprive support for any assistance on
        how to utilise this.
      </p>

      <h4>How do I add my earnings to my Sprive account?</h4>
      <p>
        The cash reward for each activity completed is added automatically to the
        total balance in the Sprive account. Please just allow up to 60 days for
        them to appear in your Sprive account as they get added. If we can help with
        anything else, please let us know.
      </p>
      <br/>
      <h4 id="privacy">Privacy & Security</h4>
      <h4>What kind of information do you collect?</h4>
      <p>
        We collect only the information you choose to share with us. You decide what
        profile information you want to complete. The three broad types of
        information we collect are:
      </p>
      <ol>
        <li>Information you directly provide us;</li>
        <li>Information about your use and interaction with Sprive Surveys; and</li>
        <li>
          Information collected through the platform, applications, third parties
          or other websites.
        </li>
      </ol>
      <p>
        Your profile information is stored in our cloud server system and is
        protected behind a powerful firewall. All sensitive data is highly encrypted
        and is only ever connected to your member ID to ensure high-level security
        for all of your information. You can learn more about how it’s done in our
        Privacy Policy.
      </p>

      <h4>How do you use my information?</h4>
      <p>
        When you participate in an activity, the responses you submit are provided
        to businesses as anonymous data. We never provide answers or profile
        information to businesses in a way that would allow you to be personally
        identified (unless you provide us express permission to do so). We also use
        the data we collect to maintain and constantly improve your Sprive Surveys
        experience. Your information helps customize the content, campaigns,
        surveys, and insights you see in your profile. The more you interact with
        your profile, the more relevant campaigns and offers you’ll be invited to
        participate in.
      </p>

      <h4>How does our privacy policy ensure your data is safe?</h4>
      <p>
        Your profile information is stored in our cloud server system that is
        protected behind a powerful firewall. All sensitive data is highly encrypted
        and is only ever connected to your Member ID to ensure high-level security
        for all of your information. Read our Privacy Policy for more information.
        Our Privacy Policy ensures that the responses you submit during an activity
        are provided to businesses in a de-identified format. We will never provide
        your responses or profile information to businesses in a way that would
        allow you to be personally identified (unless you provide us express
        permission to do so).
      </p>

    </div>
  </section>
);

const PrivacyPage = () => (
  <>
    <SEO title="Surveys FAQ" />
    <div className="common-page faq-page">
      <HeadingSection h1="Surveys Frequently Asked Questions" image={imageIllustrationFAQ} />
      <SecondSection />
    </div>
  </>
);

export default PrivacyPage;
